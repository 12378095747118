"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInvoiceChargeable = exports.getMinimumChargeable = void 0;
var MINIMUMS_PER_CURRENCY = {
    ZAR: 60,
    GBP: 3,
};
var getMinimumChargeable = function (rI) { var _a, _b; return (_b = MINIMUMS_PER_CURRENCY[(_a = rI.currency) !== null && _a !== void 0 ? _a : 'AUD']) !== null && _b !== void 0 ? _b : 5; };
exports.getMinimumChargeable = getMinimumChargeable;
var isInvoiceChargeable = function (rI) { return rI.total > (0, exports.getMinimumChargeable)(rI); };
exports.isInvoiceChargeable = isInvoiceChargeable;
