"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeStripeURL = exports.getLast4 = void 0;
var utils_1 = require("../utils");
var PAYMENT_FIELDS = ['card', 'auBecsDebit', 'nzBankAccount', 'bacsDebit'];
var getLast4 = function (paymentMethod) { return paymentMethod && PAYMENT_FIELDS
    .map(function (f) { var _a; return (_a = paymentMethod[f]) === null || _a === void 0 ? void 0 : _a.last4; }).find(utils_1.notFalsy); };
exports.getLast4 = getLast4;
var getConnectPart = function (payConn) { return (payConn === null || payConn === void 0 ? void 0 : payConn.accountId) ? "/connect/accounts/".concat(payConn === null || payConn === void 0 ? void 0 : payConn.accountId) : ''; };
var makeStripeURL = function (rechInv, isAdmin, payConn) { return rechInv.inPaymentPlatformId
    && "https://dashboard.stripe.com".concat(isAdmin ? getConnectPart(payConn) : '', "/invoices/").concat(rechInv.inPaymentPlatformId); };
exports.makeStripeURL = makeStripeURL;
