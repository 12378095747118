"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yearPickerStyles = void 0;
var yearPickerStyles = function (_a) {
    var theme = _a.theme;
    return ({
        ' .PrivatePickersYear-yearButton': {
            '&.Mui-selected': { color: theme.palette.common.white },
            '&:hover': { backgroundColor: theme.palette.secondary.light },
        },
    });
};
exports.yearPickerStyles = yearPickerStyles;
